import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import Seo from "../components/seo"
import { Col, Row } from "react-bootstrap";

// eslint-disable-next-line
export const AboutPageTemplate = ({ title, content, contentComponent }) => {
    const PageContent = contentComponent || Content;
    // const location = "about"

    // const { state = {} } = location
    // const { eventKey } = state

    // console.info("about state eventkey: ",  eventKey)
    // console.info("about page location pathname: ", location)

    return (
        <>
            <Row>
                <Col className="col-lg-8 pb-2">
                    <h1 className="page-title title-border">
                        {title}
                    </h1>
                </Col>
            </Row>
            <Row>
                <Col className="col-lg-10 offset-lg-2 bg-img">
                <Row>
                    <Col className="col-lg-7">
                        <PageContent className="content kalk-content" content={content} />
                    </Col>
                </Row>
                </Col>
            </Row>
        </>
    );
};

AboutPageTemplate.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string,
    contentComponent: PropTypes.func,
};

const AboutPage = ({ data }) => {
    const { markdownRemark: post } = data;
    const location = "about"

    return (
        <Layout location={location}>
            <AboutPageTemplate
                contentComponent={HTMLContent}
                title={post.frontmatter.title}
                content={post.html}
            />
        </Layout>
    );
};

AboutPage.propTypes = {
    data: PropTypes.object.isRequired,
};

export default AboutPage;

export const Head = () => <Seo title="About" />

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
